import React from 'react'

export const Banner_Area = () => {
  return <>
  
  <section className="banner-area padding-top-100 padding-bottom-210">
    <div className="banner-shapes">
      <span className="b-shape-1 item-animateOne"><img src="assets/images/img/5.png" alt="" /></span>
      <span className="b-shape-2 item-animateTwo"><img src="assets/images/img/6.png" alt="" /></span>
      <span className="b-shape-3 item-bounce"><img src="assets/images/img/7.png" alt="" /></span>
      <span className="b-shape-4"><img src="assets/images/img/9.png" alt="" /></span>
      <span className="b-shape-5"><img src="assets/images/shapes/18.png" alt="" /></span>
    </div>
    <div className="container padding-top-145">
      <div className="row justify-content-center align-items-center">
        <div className="col-md-12 col-lg-6 col-xl-6">
          <div className="banner-content">
            <h1>uživajte u našoj <span>hrani</span></h1>
            <div className="buyone-shape">
              <div className="banner-tag">
                <h5>kupiš jednu, druga</h5>
              </div>
              <span className="banner-badge">besplatno</span>
            </div>
            <div className="cijena">cijena : <span>$13.50</span></div>

            <div className="order-box">
              <span className="order-img"><img src="assets/images/icons/1.png" alt="" /></span>
              <div className="order-content">
                <p>dostava</p>
                <span>095/5879-4069</span>
              </div>
              <a href="#" className="btn">iskušajte odmah</a>
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block col-lg-6 col-xl-6">
          <div className="banner-img">
            <div className="pizza-shapes">
              <span className="p-shape-1"><img src="assets/images/img/2.png" alt="" /></span>
              <span className="p-shape-2"><img src="assets/images/img/3.png" alt="" /></span>
              <span className="p-shape-3"><img src="assets/images/img/4.png" alt="" /></span>
              <span className="p-shape-4"><img src="assets/images/img/8.png" alt="" /></span>
            </div>
            <img src="assets/images/img/1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  </>
}
