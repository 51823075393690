/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Footer } from './components/footer'
import { Blog } from './components/blog';
import { Delivery_Area } from './components/delivery_area';
import { Slider_Gallery } from './components/slider_gallery';
import { Testimonial } from './components/testimonial';
import { Countdown } from './components/countdown';
import { Banner_Gallery } from './components/banner_gallery';
import { Popular_Dishes } from './components/popular_dishes';
import { Popular_Menu } from './components/popular_menu';
import { About_us } from './components/about_us';
import { Form_Area } from './components/form_area';
import { Banner_Area } from './components/banner_area';
import { Preloader } from './components/preloader';
import { Header_Menu } from './components/header_menu';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>

    <Preloader />


    {/* <Header_Menu/> */}



    <Banner_Area />

    <Form_Area />


    <About_us />

    <Popular_Menu />



    <Popular_Dishes />

    <Banner_Gallery />

    <Countdown />

    <Testimonial />
    <Slider_Gallery />


    <Delivery_Area />
    <Blog />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
