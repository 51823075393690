import React from 'react'

export const Form_Area = () => {
  return <>
    <section className="form-area">
    <div className="container">
      <div className="form-box padding-top-110 padding-bottom-80">
        <div className="form-shapes">
          <span className="fs-1"><img src="assets/images/shapes/f-shape-1.png" alt="" /></span>
          <span className="fs-2"><img src="assets/images/shapes/f-shape-2.png" alt="" /></span>
          <span className="fs-3 item-animateOne"><img src="assets/images/shapes/f-shape-7.png" alt="" /></span>
          <span className="fs-5"><img src="assets/images/shapes/4.png" alt="" /></span>
          <span className="fs-6"><img src="assets/images/shapes/5.png" alt="" /></span>
          <span className="fs-7 item-animateTwo"><img src="assets/images/shapes/7.png" alt="" /></span>
          <span className="fs-8 item-animateOne"><img src="assets/images/shapes/9.png" alt="" /></span>
        </div>
        <div className="common-title-area text-center padding-bottom-50 wow fadeInUp">
          <h3> Rezervacije</h3>
          <h2>Stol <span>rezervacija</span></h2>
        </div>
        <div className="row">
          <div className="col-lg-10 offset-lg-1 col-md-12">
            <div className="form-wraper padding-bottom-40">
              <form action="#">
                <select className="form-item2">
                  <option value="">4 osobe</option>
                  <option value="">3 osobe</option>
                  <option value="">2 osobe</option>
                  <option value="">1 osoba</option>
                </select>
                <input className="form-item2" type="date" />
                <select className="form-item2">
                  <option value="">07:24 pm</option>
                  <option value="">07:24 pm</option>
                  <option value="">07:24 pm</option>
                  <option value="">07:24 pm</option>
                </select>
                <button type="submit">pronađi</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  </>
}
