import React from 'react'

export const Popular_Menu = () => {
  return <>
  <section className="menu-area padding-bottom-120">
    <div className="container">
      <div className="common-title-area text-center padding-50 wow fadeInUp">
        <h3>Odredišta</h3>
        <h2>popularne <span>destinacije</span></h2>
      </div>
      <div className="menu-nav-wrapper">
        <div className="container">
          <div className="row">
            <nav>
              <div className="nav" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active" id="nav-menu-1" data-toggle="tab" href="#menu-1" role="tab"
                  aria-controls="menu-1" aria-selected="true">
                  <div className="single-menu-nav text-center">
                    <div className="menu-img margin-bottom-10">
                      <img src="assets/images/menu-item/pizza.png" alt="" />
                    </div>
                    <h6>Pizze</h6>
                    <span className="g-s-4"><img src="assets/images/shapes/10.png" alt="" /></span>
                    <span className="g-s-5"><img src="assets/images/shapes/14.png" alt="" /></span>
                  </div>
                </a>
                <a className="nav-item nav-link" id="nav-menu-2" data-toggle="tab" href="#menu-2" role="tab"
                  aria-controls="menu-2" aria-selected="false">
                  <div className="single-menu-nav text-center">
                    <div className="menu-img margin-bottom-10">
                      <img src="assets/images/menu-item/burger.png" alt="" />
                    </div>
                    <h6>Roštilji</h6>
                    <span className="g-s-4"><img src="assets/images/shapes/10.png" alt="" /></span>
                    <span className="g-s-5"><img src="assets/images/shapes/14.png" alt="" /></span>
                  </div>
                </a>
                <a className="nav-item nav-link" id="nav-menu-3" data-toggle="tab" href="#menu-3" role="tab"
                  aria-controls="menu-3" aria-selected="false">
                  <div className="single-menu-nav text-center">
                    <div className="menu-img margin-bottom-10">
                      <img src="assets/images/menu-item/chicken.png" alt="" />
                    </div>
                    <h6>Riba</h6>
                    <span className="g-s-4"><img src="assets/images/shapes/10.png" alt="" /></span>
                    <span className="g-s-5"><img src="assets/images/shapes/14.png" alt="" /></span>
                  </div>
                </a>
                <a className="nav-item nav-link" id="nav-menu-4" data-toggle="tab" href="#menu-4" role="tab"
                  aria-controls="menu-4" aria-selected="false">
                  <div className="single-menu-nav text-center">
                    <div className="menu-img margin-bottom-10">
                      <img src="assets/images/menu-item/drinks.png" alt="" />
                    </div>
                    <h6>Vegetarijanska</h6>
                    <span className="g-s-4"><img src="assets/images/shapes/10.png" alt="" /></span>
                    <span className="g-s-5"><img src="assets/images/shapes/14.png" alt="" /></span>
                  </div>
                </a>
                <a className="nav-item nav-link" id="nav-menu-5" data-toggle="tab" href="#menu-5" role="tab"
                  aria-controls="menu-5" aria-selected="false">
                  <div className="single-menu-nav text-center">
                    <div className="menu-img margin-bottom-10">
                      <img src="assets/images/menu-item/drinks2.png" alt="" />
                    </div>
                    <h6>Tjestenine</h6>
                    <span className="g-s-4"><img src="assets/images/shapes/10.png" alt="" /></span>
                    <span className="g-s-5"><img src="assets/images/shapes/14.png" alt="" /></span>
                  </div>
                </a>
                <a className="nav-item nav-link" id="nav-menu-6" data-toggle="tab" href="#menu-6" role="tab"
                  aria-controls="menu-6" aria-selected="false">
                  <div className="single-menu-nav text-center">
                    <div className="menu-img margin-bottom-10">
                      <img src="assets/images/menu-item/combo.png" alt="" />
                    </div>
                    <h6>Kokteli</h6>
                    <span className="g-s-4"><img src="assets/images/shapes/10.png" alt="" /></span>
                    <span className="g-s-5"><img src="assets/images/shapes/14.png" alt="" /></span>
                  </div>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade show active" id="menu-1" role="tabpanel" aria-labelledby="menu-1">
          <div className="menu-items-wrapper menu-custom-padding margin-top-50">
            <div className="menu-i-shapes">
              <span className="mis-1"><img src="assets/images/shapes/28.png" alt="" /></span>
              <span className="mis-2"><img src="assets/images/shapes/12.png" alt="" /></span>
              <span className="mis-3"><img src="assets/images/shapes/7.png" alt="" /></span>
              <span className="mis-4"><img src="assets/images/shapes/17.png" alt="" /></span>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/piz1.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Margherita</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$17.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/piz2.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Tomato</a></h5>
                    <p>krompir. rajcica, jabuka</p>
                    <span>cijena :$14.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/piz3.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Marinara</a></h5>
                    <p>tjestenina. hobotnica, jabuka</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row margin-top-60">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/piz4.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Frutti Di Mare</a></h5>
                    <p>hoba. riba, jabuka</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/piz5.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Americana</a></h5>
                    <p>pasta. luk, sir</p>
                    <span>cijena :$13.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/piz6.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Pizza Al Pesto</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$14.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className="menu-btn text-center">
              <a href="shopping-cart.html" className="btn">Prikaži još</a>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="menu-2" role="tabpanel" aria-labelledby="menu-2">
          <div className="menu-items-wrapper menu-custom-padding margin-top-50">
            <div className="menu-i-shapes">
              <span className="mis-1"><img src="assets/images/shapes/28.png" alt="" /></span>
              <span className="mis-2"><img src="assets/images/shapes/12.png" alt="" /></span>
              <span className="mis-3"><img src="assets/images/shapes/7.png" alt="" /></span>
              <span className="mis-4"><img src="assets/images/shapes/17.png" alt="" /></span>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-1.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html"> Burger sa cesnjakom</a></h5>
                    <p>kozji sir. prsut, masline</p>
                    <span>cijena :$5.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/br1.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">naga burger</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$16.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/br2.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Govedina Burger</a></h5>
                    <p>sir. govedina, mjesano meso</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row margin-top-60">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/br3.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">BBQ Burger</a></h5>
                    <p>lignje. sir, ananas</p>
                    <span>cijena :$12.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/br5.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Crispy Burger</a></h5>
                    <p>lignje. hobotnica, govedina</p>
                    <span>cijena :$12.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/br6.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Chicken Grill</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className="menu-btn text-center">
              <a href="shopping-cart.html" className="btn">prikaži još</a>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="menu-3" role="tabpanel" aria-labelledby="menu-3">
          <div className="menu-items-wrapper menu-custom-padding margin-top-50">
            <div className="menu-i-shapes">
              <span className="mis-1"><img src="assets/images/shapes/28.png" alt="" /></span>
              <span className="mis-2"><img src="assets/images/shapes/12.png" alt="" /></span>
              <span className="mis-3"><img src="assets/images/shapes/7.png" alt="" /></span>
              <span className="mis-4"><img src="assets/images/shapes/17.png" alt="" /></span>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="single-menu-item d-flex justify-content-between">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/tc6.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Fried Chicken</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-2.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Thai Chicken</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-3.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Bbq Chicken</a></h5>
                    <p>lignje. riba, ananas</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row margin-top-60">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-1.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">naga</a></h5>
                    <p>lignje. riba, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-5.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Thai Chicken</a></h5>
                    <p>lignje. krompir, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-6.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Bbq Chicken</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className="menu-btn text-center">
              <a href="shopping-cart.html" className="btn">prikaži još</a>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="menu-4" role="tabpanel" aria-labelledby="menu-4">
          <div className="menu-items-wrapper menu-custom-padding margin-top-50">
            <div className="menu-i-shapes">
              <span className="mis-1"><img src="assets/images/shapes/28.png" alt="" /></span>
              <span className="mis-2"><img src="assets/images/shapes/12.png" alt="" /></span>
              <span className="mis-3"><img src="assets/images/shapes/7.png" alt="" /></span>
              <span className="mis-4"><img src="assets/images/shapes/17.png" alt="" /></span>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr1.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html"> Grapefruit</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr2.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Blueberry</a></h5>
                    <p>lignje. jabuka, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr3.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Cranberry</a></h5>
                    <p>lignje. paprika, ananas</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row margin-top-60">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr4.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Orange Juice</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr5.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Beet Juice</a></h5>
                    <p>lignje. marmelada, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr6.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Pomegranate</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className="menu-btn text-center">
              <a href="shopping-cart.html" className="btn">prikaži još</a>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="menu-5" role="tabpanel" aria-labelledby="menu-5">
          <div className="menu-items-wrapper menu-custom-padding margin-top-50">
            <div className="menu-i-shapes">
              <span className="mis-1"><img src="assets/images/shapes/28.png" alt="" /></span>
              <span className="mis-2"><img src="assets/images/shapes/12.png" alt="" /></span>
              <span className="mis-3"><img src="assets/images/shapes/7.png" alt="" /></span>
              <span className="mis-4"><img src="assets/images/shapes/17.png" alt="" /></span>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr2.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Guava juice</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr5.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Lemonade</a></h5>
                    <p>lignje. riba, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr1.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Lingonberry</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row margin-top-60">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr6.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Prune juice</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr4.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Sugarcane</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/img/dr5.jpg" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Vegetable</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className="menu-btn text-center">
              <a href="shopping-cart.html" className="btn">prikaži još</a>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="menu-6" role="tabpanel" aria-labelledby="menu-6">
          <div className="menu-items-wrapper menu-custom-padding margin-top-50">
            <div className="menu-i-shapes">
              <span className="mis-1"><img src="assets/images/shapes/28.png" alt="" /></span>
              <span className="mis-2"><img src="assets/images/shapes/12.png" alt="" /></span>
              <span className="mis-3"><img src="assets/images/shapes/7.png" alt="" /></span>
              <span className="mis-4"><img src="assets/images/shapes/17.png" alt="" /></span>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-1.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Garlic Burger</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-2.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Thai Chicken</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-3.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Bbq Chicken</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="row margin-top-60">
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-4.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Mutton</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-5.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Thai Chicken</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="
                      single-menu-item
                      d-flex
                      justify-content-between
                      align-items-center
                      h-100
                    ">
                  <div className="menu-img">
                    <img src="assets/images/menu-item/mi-6.png" alt="" />
                  </div>
                  <div className="menu-content">
                    <h5><a href="single-dish.html">Bbq Chicken</a></h5>
                    <p>lignje. hobotnica, ananas</p>
                    <span>cijena :$21.00</span>
                    <span className="del"><del>cijena :$21.00</del></span>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className="menu-btn text-center">
              <a href="shopping-cart.html" className="btn">prikaži još</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
}
