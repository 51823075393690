import React from 'react'

export const Countdown = () => {
  return <>
  <section className="countdown-area padding-top-120 padding-bottom-120">
    <div className="container">
      <div className="countdown-shapes">
        <span className="cs-1 item-bounce"><img src="assets/images/shapes/24.png" alt="" /></span>
        <span className="cs-3 item-bounce"><img src="assets/images/shapes/26.png" alt="" /></span>
        <span className="cs-4 item-animateOne"><img src="assets/images/shapes/27.png" alt="" /></span>
        <span className="cs-5"><img src="assets/images/shapes/18.png" alt="" /></span>
        <span className="cs-6"><img src="assets/images/shapes/22.png" alt="" /></span>
        <span className="cs-7"><img src="assets/images/shapes/30.png" alt="" /></span>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 margin-bottom-20">
          <div className="countdown-left">
            <span className="cs-1"><img src="assets/images/shapes/25.png" alt="" /></span>
            <span className="cs-2"><img src="assets/images/shapes/Leaf.png" alt="" /></span>
            <span className="cs-3"><img src="assets/images/shapes/Leaf4.png" alt="" /></span>
            <span className="cs-4"><img src="assets/images/img/3.png" alt="" /></span>
            <span className="cs-5"><img src="assets/images/shapes/tomato.png" alt="" /></span>
            <span className="cs-6"><img src="assets/images/shapes/onions.png" alt="" /></span>
            <span className="cs-7"><img src="assets/images/shapes/Leaf2.png" alt="" /></span>
            <span className="cs-8"><img src="assets/images/shapes/Leaf3.png" alt="" /></span>
            <img src="assets/images/img/21.png" alt="" />
          </div>
        </div>
        <div className="col-lg-5 offset-lg-1 col-md-12 col-sm-12 col-12">
          <div className="countdown-right">
            <div className="common-title-area padding-bottom-20">
              <h3>Uskoro</h3>
              <h2>Janjetina <span> Dostava </span></h2>
              <p>Gladni ste? Naručite Vašu omiljenu hranu.</p>
            </div>
            <div className="count-box countdown">
              <div>
                <span className="days">00</span>
                <p className="days_ref">dana</p>
              </div>
              <span className="seperator">:</span>
              <div>
                <span className="hours">00</span>
                <p className="hours_ref">sati</p>
              </div>
              <span className="seperator">:</span>
              <div>
                <span className="minutes">00</span>
                <p className="minutes_ref">minuta</p>
              </div>
              <span className="seperator">:</span>
              <div>
                <span className="seconds">00</span>
                <p className="seconds_ref">seconds</p>
              </div>
            </div>
            <a href="shopping-cart.html" className="btn">Naruči</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
}
