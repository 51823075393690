import React from 'react'

export const Popular_Dishes = () => {
  return <>
  <section className="popular-dishes-area padding-top-110">
    <div className="pshapes">
      <span className="ps-1 item-animateTwo"><img src="assets/images/shapes/11.png" alt="" /></span>
      <span className="ps-2 item-animateTwo"><img src="assets/images/shapes/12.png" alt="" /></span>
      <span className="ps-3 item-bounce"><img src="assets/images/shapes/13.png" alt="" /></span>
      <span className="ps-4 item-bounce"><img src="assets/images/shapes/14.png" alt="" /></span>
      <span className="ps-5"><img src="assets/images/shapes/21.png" alt="" /></span>
      <span className="ps-6"><img src="assets/images/shapes/16.png" alt="" /></span>
    </div>
    <div className="container">
      <nav className="
            popular-tab-nav
            d-flex
            flex-wrap
            justify-content-between
            align-items-center
          ">
        <div className="common-title-area padding-bottom-30 wow fadeInLeft">
          <h3>Specijaliteti</h3>
          <h2>Najčešće <span>naruđbe</span></h2>
        </div>
        <div className="nav padding-bottom-30 wow fadeInRight" id="nav-tab-1" role="tablist">
          <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
            aria-selected="true">sve</a>
          <a className="nav-link" id="nav-tab-home" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1"
            aria-selected="false">pizza</a>

          <a className="nav-link" id="nav-tab-11" data-toggle="tab" href="#tab-2" role="tab" aria-controls="tab-2"
            aria-selected="false">burger</a>

          <a className="nav-link" id="nav-tab-2" data-toggle="tab" href="#tab-3" role="tab" aria-controls="tab-3"
            aria-selected="false">piletina</a>

          <a className="nav-link" id="nav-tab-3" data-toggle="tab" href="#tab-4" role="tab" aria-controls="tab-4"
            aria-selected="false">pića</a>
        </div>
      </nav>


      <div className="tab-content" id="nav-tabContent-1">
        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd1.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Burger sa češnjakom </a></h5>
                  <p>
                    Pikantni burger sa ljubitelje ljutoga.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">ljuto</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd2.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Vegetarijanska Pizza </a></h5>
                  <p>
                    Luk, paprika, masline, kukuruz, grašak, rajčica i još mnogo povrća. Složeno za Vaš užitak.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd3.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html"> Pečena piletina </a></h5>
                  <p>
                    Pečena piletina dobro u pacana u najukusnije začine.
                  </p>
                  <span className="cijena badge-color">cijena :$11.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5 </span>
                </div>
                <span className="badge badge-bg-color">novo</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd4.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Juha od ribe</a></h5>
                  <p>
                    Riblja juha s rižom i gljivama.
                  </p>
                  <span className="cijena">cijena :$17.00</span>
                </div>
                <span className="badge">popust</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd5.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Salata od tune </a></h5>
                  <p>
                    Salata od tune po pariškom receptu sa umakom od gorgonzole. Izaberite sastojke.
                  </p>
                  <span className="cijena">cijena :$19.00</span>
                </div>
                <span className="badge">slano</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd6.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Salata s hobotnicom </a></h5>
                  <p>
                    Probajte našu salatu od hobotnice i osjetite okuse mediterana.
                  </p>
                  <span className="cijena">cijena :$12.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd7.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Pileće pečenje </a></h5>
                  <p>
                    Pečeno pile uzgojeno na partner farmi odiše mirisom i okusom.
                  </p>
                  <span className="badge-color cijena">cijena :$21.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd8.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Juha od rajčice </a></h5>
                  <p>
                    Juha od domaćih rajčica, eko uzgoj iz podravine.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">sniženje</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-pane fade" id="tab-1" role="tabpanel" aria-labelledby="tab-1">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd5.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Margherita </a></h5>
                  <p>
                    Mješana piyya sam19 eura.
                  </p>
                  <span className="cijena">cijena :$10.00</span>
                </div>
                <span className="badge">sniženje</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd5.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Siciliana </a></h5>
                  <p>
                    Pizza sa sardinama, maslinama, rajčicom i kamberima.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd5.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Marinara </a></h5>
                  <p>
                    Pizza od sira, luka i maslina.
                  </p>
                  <span className="cijena badge-color">cijena :$21.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5</span>
                </div>
                <span className="badge badge-bg-color">novo</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd5.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Frutti Di Mare </a></h5>
                  <p>
                    Pizza sa plodovima mora.
                  </p>
                  <span className="cijena">cijena :$11.00</span>
                </div>
                <span className="badge">rasprodaja</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd5.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Americanina </a></h5>
                  <p>
                    Jaja i špek glavni su sastojci ove pizze.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">ljuto</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd5.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Quatro stagioni </a></h5>
                  <p>
                    Pizza sa četiri vrste sira. 
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd5.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Pizza Al Pesto </a></h5>
                  <p>
                    Tjesto ispunjeno sirom i rajcicom.
                  </p>
                  <span className="badge-color cijena">cijena :$21.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd5.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Slavonska </a></h5>
                  <p>
                    Kulen, kobasica, luk i špek glavni su sastojci Slavonske.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">sniženje</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="tab-2">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd1.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Pečenje sa krumpirom </a></h5>
                  <p>
                    Pečenje sa krumpirom i mesom.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">ljuto</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd1.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html"> Sarma </a></h5>
                  <p>
                    Naša sarma najbolja je u Hrvatskoj.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd1.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Kiseli kupus </a></h5>
                  <p>
                   Meso i kiseli kupus jako su dobra i ukusna kombinacija.
                  </p>
                  <span className="cijena badge-color">cijena :$21.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5</span>
                </div>
                <span className="badge badge-bg-color">novo</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd1.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Juha od šampinjona</a></h5>
                  <p>
                    Krem juha od šampinjona.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">sniženo</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd1.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Jastog </a></h5>
                  <p>
                    Jastog sa kuhanom tjesteninom.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">ljuto</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd1.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Zubatac na žaru</a></h5>
                  <p>
                    Pečeni zubatac na žaru je samo ponedeljkom.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd1.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Orada na zaru </a></h5>
                  <p>
                    Orada na žaru za sladokusce.
                  </p>
                  <span className="badge-color cijena">cijena :$21.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd1.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Juha od Gljiva</a></h5>
                  <p>
                   Juha od gljiva jendo od najjacih jela.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">sale</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="tab-3">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd8.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Sladoled </a></h5>
                  <p>
                    Veliki izbor sladoleda od čokolade, vanilije, jagode i kiwija.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">sniženo</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd8.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Kolači</a></h5>
                  <p>
                    Mađarica.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd8.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Štrudla</a></h5>
                  <p>
                    Štrudla sa jabukom ili sirom.
                  </p>
                  <span className="cijena badge-color">cijena :$21.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5</span>
                </div>
                <span className="badge badge-bg-color">novo</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd8.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Špagete </a></h5>
                  <p>
                    Špagete sa sirom.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">sale</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd8.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Tjestenina </a></h5>
                  <p>
                    Bolognese tjestenina sa mješanim mesom.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">ljuto</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd8.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Palačinke </a></h5>
                  <p>
                    Palačinke od marmelade.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd8.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Svinjetina na pariški </a></h5>
                  <p>
                    Pečena svinjetina po pariškom receptu.
                  </p>
                  <span className="badge-color cijena">cijena :$21.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd8.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Kompot </a></h5>
                  <p>
                    Kompot od jabuke, kruške ili višnje.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">sniženo</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="tab-pane fade" id="tab-4" role="tabpanel" aria-labelledby="tab-4">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd2.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Pire s odreskom </a></h5>
                  <p>
                    Odrezak sa pire krumpirom.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">hot</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd2.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Voće</a></h5>
                  <p>
                    Voće i povrće ya Vas.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd2.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Piva </a></h5>
                  <p>
                    Veliki izbor pive.
                  </p>
                  <span className="cijena badge-color">cijena :$21.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5</span>
                </div>
                <span className="badge badge-bg-color">novo</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd2.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Viski</a></h5>
                  <p>
                   Jaka alkoholna pića
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">sale</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd2.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Sok </a></h5>
                  <p>
                        Sok od naranže ili jabuke.                  
                    </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">hot</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd2.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Kava </a></h5>
                  <p>
                    Veliki izbor kave.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd2.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Vino </a></h5>
                  <p>
                    Crno ili bjelo vino.
                  </p>
                  <span className="badge-color cijena">cijena :$21.00</span>
                  <span className="rating">
                    <i className="fas fa-star"></i> 5star</span>
                </div>
                <span className="badge"></span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="single-dishes">
                <div className="dish-img">
                  <img src="assets/images/menu-item/pd2.png" style={{width: "inherit"}} alt="" />
                </div>
                <div className="dish-content">
                  <h5><a href="single-dish.html">Coca Cola </a></h5>
                  <p>
                    Coca cola sa i bez šećera.
                  </p>
                  <span className="cijena">cijena :$21.00</span>
                </div>
                <span className="badge">sale</span>
                <div className="cart-opt">
                  <span>
                    <a href="#"><i className="fas fa-heart"></i></a>
                  </span>
                  <span>
                    <a href="shopping-cart.html"><i className="fas fa-shopping-basket"></i></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
}
