import React from 'react'

export const Slider_Gallery = () => {
  return <>
  <div className="slider-gallery-img">
    <div className="container-fluid">
      <div className="slider-gallery-active">
        <div className="single-gallery-img">
          <img src="assets/images/gallery/gm1.jpg" alt="" />
          <a href="gallery.html"><span><i className="fas fa-image"></i></span></a>
        </div>
        <div className="single-gallery-img">
          <img src="assets/images/gallery/gm2.jpg" alt="" />
          <a href="gallery.html"><span><i className="fas fa-image"></i></span></a>
        </div>
        <div className="single-gallery-img">
          <img src="assets/images/gallery/gm3.jpg" alt="" />
          <a href="gallery.html"><span><i className="fas fa-image"></i></span></a>
        </div>
        <div className="single-gallery-img">
          <img src="assets/images/gallery/gm4.jpg" alt="" />
          <a href="gallery.html"><span><i className="fas fa-image"></i></span></a>
        </div>
        <div className="single-gallery-img">
          <img src="assets/images/gallery/gm5.jpg" alt="" />
          <a href="gallery.html"><span><i className="fas fa-image"></i></span></a>
        </div>
        <div className="single-gallery-img">
          <img src="assets/images/gallery/gm6.jpg" alt="" />
          <a href="gallery.html"><span><i className="fas fa-image"></i></span></a>
        </div>
      </div>
    </div>
  </div>
  </>
}
