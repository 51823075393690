import React from 'react'

export const Blog
 = () => {
  return <>  
  <section className="blog-area padding-top-110 padding-bottom-120">
    <div className="blog-shapes">
      <span className="bs-1"><img src="assets/images/img/37.png" alt="" /></span>
    </div>
    <div className="container">
      <div className="common-title-area text-center padding-bottom-60 wow fadeInUp">
        <h3>Gablec.com</h3>
        <h2>blog & <span> vjesti </span></h2>
      </div>
      <div className="blog-slider-active">
        <div className="single-blog">
          <div className="blog-img">
            <a href="blog-single.html">
              <img src="assets/images/blog/b-1.jpg" alt="" /></a>
            <div className="b-badge">
              <span className="date"><a href="#">02</a></span><br />
              <span className="month"><a href="#">sječanj</a></span>
            </div>
          </div>
          <div className="blog-meta d-flex justify-content-between">
            <span><a href="#"><i className="fas fa-tags"></i> pileći odrezak</a></span>
            <span><a href="#"><i className="fas fa-user-circle"></i> Pavle Stanić</a></span>
          </div>
          <h4>
            <a href="blog-single.html">Pečenje sa krumpirom</a>
          </h4>
        </div>
        <div className="single-blog">
          <div className="blog-img">
            <a href="blog-single.html">
              <img src="assets/images/blog/b-2.jpg" alt="" /></a>
            <div className="b-badge">
              <span className="date"><a href="#">02</a></span><br />
              <span className="month"><a href="#">svibanj</a></span>
            </div>
          </div>
          <div className="blog-meta d-flex justify-content-between">
            <span><a href="#"><i className="fas fa-tags"></i> pizza</a></span>
            <span><a href="#"><i className="fas fa-user-circle"></i> Ante</a></span>
          </div>
          <h4>
            <a href="blog-single.html">Ukusna pizza iz krušne peći</a>
          </h4>
        </div>
        <div className="single-blog">
          <div className="blog-img">
            <a href="blog-single.html">
              <img src="assets/images/blog/b-3.jpg" alt="" /></a>
            <div className="b-badge">
              <span className="date"><a href="#">12</a></span><br />
              <span className="month"><a href="#">sječanj</a></span>
            </div>
          </div>
          <div className="blog-meta d-flex justify-content-between">
            <span><a href="#"><i className="fas fa-tags"></i> Čevapi s lepinjom</a></span>
            <span><a href="#"><i className="fas fa-user-circle"></i> Ivan</a></span>
          </div>
          <h4>
            <a href="blog-single.html">Čevapi na žaru</a>
          </h4>
        </div>
        <div className="single-blog">
          <div className="blog-img">
            <a href="blog-single.html">
              <img src="assets/images/blog/b-1.jpg" alt="" /></a>
            <div className="b-badge">
              <span className="date"><a href="#">02</a></span><br />
              <span className="month"><a href="#">svibanj</a></span>
            </div>
          </div>
          <div className="blog-meta d-flex justify-content-between">
            <span><a href="#"><i className="fas fa-tags"></i> goveđi burger</a></span>
            <span><a href="#"><i className="fas fa-user-circle"></i> Petar Perić</a></span>
          </div>
          <h4>
            <a href="blog-single.html">Trešnjevački burger</a>
          </h4>
        </div>
        <div className="single-blog">
          <div className="blog-img">
            <a href="blog-single.html">
              <img src="assets/images/blog/b-2.jpg" alt="" /></a>
            <div className="b-badge">
              <span className="date"><a href="#">02</a></span><br />
              <span className="month"><a href="#">prosinac</a></span>
            </div>
          </div>
          <div className="blog-meta d-flex justify-content-between">
            <span><a href="#"><i className="fas fa-tags"></i> pizza</a></span>
            <span><a href="#"><i className="fas fa-user-circle"></i> Ivana</a></span>
          </div>
          <h4>
            <a href="blog-single.html">Ukusna pizza ispod peke</a>
          </h4>
        </div>
        <div className="single-blog">
          <div className="blog-img">
            <a href="blog-single.html">
              <img src="assets/images/blog/b-3.jpg" alt="" /></a>
            <div className="b-badge">
              <span className="date"><a href="#">02</a></span><br />
              <span className="month"><a href="#">ožujak</a></span>
            </div>
          </div>
          <div className="blog-meta d-flex justify-content-between">
            <span><a href="#"><i className="fas fa-tags"></i> pileći burger</a></span>
            <span><a href="#"><i className="fas fa-user-circle"></i> specijaliteti</a></span>
          </div>
          <h4>
            <a href="blog-single.html">Najbolji hamburger u gradu.</a>
          </h4>
        </div>
      </div>
    </div>
  </section>
  </>
}
