import React from 'react'

export const About_us = () => {
  return <>
  <section className="about-area padding-top-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 col-md-12 wow fadeInLeft">
          <div className="about-left">
            <div className="about-l-shapes">
              <span className="als-1"><img src="assets/images/shapes/2.png" alt="" /></span>
            </div>
            <div className="row">
              <div className="
                    col-lg-4 col-md-4 col-sm-4 col-4
                    d-flex
                    align-items-end
                    justify-content-end
                    margin-bottom-20
                  ">
                <div className="about-gallery-1">
                  <img src="assets/images/gallery/1.jpg" alt="" />
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-8 margin-bottom-20">
                <div className="about-gallery-2">
                  <img src="assets/images/gallery/2.jpg" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="about-gallery-3">
                  <img src="assets/images/gallery/3.jpg" alt="" />
                </div>
              </div>
              <div className="
                    col-lg-5 col-md-5 col-sm-5 col-5
                    d-flex
                    align-items-start
                  ">
                <div className="about-gallery-4 text-center">
                  <img className="mp" src="assets/images/icons/3.png" alt="" />
                  <div className="items counter">1983</div>
                  <p>destinacija</p>
                  <span className="g-s-4"><img src="assets/images/shapes/10.png" alt="" /></span>
                  <span className="g-s-5"><img src="assets/images/shapes/14.png" alt="" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 offset-lg-1 wow fadeInRight">
          <div className="about-right">
            <div className="about-r-shapes">
              <span className="as-1 item-bounce"><img src="assets/images/shapes/1.png" alt="" /></span>
            </div>
            <h2>
              Naši okusi i mirisi dopiru do
              <span>Vas.</span>
            </h2>
            <p>
              Vrsimo dostavu po cjeloj Hrvatskoj. Zovite nas.
            </p>
            <div className="garlic-burger-card">
              <div className="garlic-burger-img">
                <img className="cijena-badge" src="assets/images/icons/4.png" alt="" />
                <img src="assets/images/icons/2.png" alt="" />
              </div>
              <div className="garlic-burger-content">
                <h5>Ćevapi s lukom party</h5>
                <p>
                  Izbor za najljepše ćevape u Vašem gradu.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
}
