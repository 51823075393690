import React from 'react'

export const Delivery_Area = () => {
  return <>
  <section className="delivery-area padding-top-121 padding-bottom-90">
    <div className="del-shapes">
      <span className="ds-1 item-bounce"><img src="assets/images/shapes/35.png" alt="" /></span>
      <span className="ds-2"><img src="assets/images/shapes/34.png" alt="" /></span>
      <span className="ds-3 item-bounce"><img src="assets/images/shapes/17.png" alt="" /></span>
      <span className="ds-4 item-animateOne"><img src="assets/images/shapes/6.png" alt="" /></span>
    </div>
    <div className="container">
      <div className="row">
        <div className="
              col-lg-6
              align-self-lg-center
              col-md-12
              margin-bottom-20
              wow
              fadeInLeft
            ">
          <div className="delivery-left">
            <img src="assets/images/bg/delivery-img.png" alt="" />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 wow fadeInRight">
          <div className="delivery-right">
            <div className="common-title-area padding-bottom-40">
              <h3>Dostava</h3>
              <h2>
                Dostava <span> u pravo vrjeme na pravo mjesto </span>
              </h2>
              <p>
                Gablec.com je servis za dostavu hrane i organiziranje proslava.
              </p>
              <div className="order-box d-flex align-items-end">
                <span className="order-img"><img src="assets/images/icons/1.png" alt="" /></span>
                <div className="order-content">
                  <p>Nazovite nas s povjerenjem.</p>
                  <span>095/5979-4069</span>
                </div>
                <a href="shopping-cart.html" className="btn">naruči</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
}
