import React from 'react'

export const Banner_Gallery = () => {
  return <>
  <section className="banner-gallery padding-top-100 padding-bottom-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-9 col-md-12">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="gallery-img-1">
                <h3>Veliki Hamburger</h3>
                <p>Popust 50% samo ovaj tjedan</p>
                <a href="shopping-cart.html" className="btn">naručite</a>
                <img src="assets/images/gallery/24.png" alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="
                    gallery-img-2
                    d-flex
                    align-items-end
                    justify-content-end
                  ">
                <img src=" assets/images/gallery/26.png" alt="" />
                <img src="assets/images/shapes/38.png" alt="" className="s11" />
                <span className="gcijena-1">$21</span>
                <div className="gimg-content">
                  <h5>Super Fina Pizza</h5>
                  <a href="shopping-cart.html">naručite</a>
                </div>
              </div>
            </div>
          </div>
          <div className="row margin-top-30">
            <div className="col-lg-4 col-md-4">
              <div className="gallery-img-3">
                <h5>Lazanje</h5>
                <a href="shopping-cart.html">naručite</a>
                <img src="assets/images/gallery/23.png" alt="" />
                <img src="assets/images/shapes/layer2.png" alt="" className="s12" />
                <img src="assets/images/shapes/113.png" alt="" className="s13" />
                <span className="gcijena-2">$21</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="
                    gallery-img-2
                    d-flex
                    align-items-end
                    justify-content-end
                  ">
                <img src=" assets/images/gallery/26.png" alt="" />
                <img src="assets/images/shapes/38.png" alt="" className="s11" />
                <span className="gcijena-1">$22</span>
                <div className="gimg-content">
                  <h5>Paška Janjetina</h5>
                  <a href="shopping-cart.html">naruči</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="gallery-img-3">
                <h5>Voćna Salata</h5>
                <a href="shopping-cart.html">naruči</a>
                <img src="assets/images/gallery/23.png" alt="" />
                <img src="assets/images/shapes/layer2.png" alt="" className="s12" />
                <img src="assets/images/shapes/113.png" alt="" className="s13" />
                <span className="gcijena-2">$21</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12">
          <div className="gallery-img-4">
            <h5>Sladoled</h5>
            <a href="shopping-cart.html" className="btn">naruči</a>
            <img src="assets/images/gallery/22.png" alt="" />
            <img src="assets/images/shapes/leaves.png" alt="" className="s14" />
            <img src="assets/images/shapes/transparent2.png" alt="" className="s21" />
            <span className="gcijena-4"><img src="assets/images/gallery/25.png" alt="" /></span>
          </div>
        </div>
      </div>
    </div>
  </section>
  </>
}
