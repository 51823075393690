import React from 'react'

export const Preloader = () => {
  return <>
  <div className="preloader" id="preloader">
    <div className="preloader-inner">
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>
  </div>
  </>
}
