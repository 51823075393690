import React from 'react'

export const Testimonial = () => {
  return <>
  
  <section className="testimonial padding-bottom-120 padding-top-110">
    <div className="container">
      <div className="testi-shapes">
        <span className="ts-1"><img src="assets/images/img/31.png" alt="" /></span>
        <span className="ts-2"><img src="assets/images/img/32.png" alt="" /></span>
        <span className="ts-3 item-animateTwo"><img src="assets/images/shapes/7.png" alt="" /></span>
        <span className="ts-4"><img src="assets/images/shapes/26.png" alt="" /></span>
      </div>
      <div className="common-title-area text-center padding-bottom-50 wow fadeInUp">
        <h3>Svjedočanstva</h3>
        <h2>naših <span>korisnika</span></h2>
      </div>
      <div className="testimonial-active">
        <div className="single-testimonial">
          <div className="testi-top">
            <div className="tin-shapes">
              <span className="tsin-1"><img src="assets/images/shapes/33.png" alt="" /></span>
            </div>
            <div className="testi-img">
              <img src="assets/images/testimonial/testi-1.png" alt="" />
            </div>
            <div className="testi-meta">
              <h6>Ivan Purger</h6>
              <p>CEO ACroTech Ltd.</p>
              <div className="testi-rating">
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
              </div>
            </div>
          </div>
          <p>
            Gablec je odlican servis, dostava je brza a jela svježa i ukusna.
          </p>
        </div>
        <div className="single-testimonial">
          <div className="testi-top">
            <div className="tin-shapes">
              <span className="tsin-1"><img src="assets/images/shapes/33.png" alt="" /></span>
            </div>
            <div className="testi-img">
              <img src="assets/images/testimonial/testi-2.png" alt="" />
            </div>
            <div className="testi-meta">
              <h6>Maja Gerner</h6>
              <p>direktorica Farmacro Ltd.</p>
              <div className="testi-rating">
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
              </div>
            </div>
          </div>
          <p>
            Gablec ima veliki izbor, dostava je brza, a namirnice svježe. Svoje buduće cateringe ću naručivati od gablec.com.
          </p>
        </div>
        <div className="single-testimonial">
          <div className="testi-top">
            <div className="tin-shapes">
              <span className="tsin-1"><img src="assets/images/shapes/33.png" alt="" /></span>
            </div>
            <div className="testi-img">
              <img src="assets/images/testimonial/testi-1.png" alt="" />
            </div>
            <div className="testi-meta">
              <h6>Pavle Ivanov</h6>
              <p>Cronet</p>
              <div className="testi-rating">
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
              </div>
            </div>
          </div>
          <p>
            Gablec je moj prvi izbor. Ukazano povjerenje mi se višestruko isplatilo.
          </p>
        </div>
        <div className="single-testimonial">
          <div className="testi-top">
            <div className="tin-shapes">
              <span className="tsin-1"><img src="assets/images/shapes/33.png" alt="" /></span>
            </div>
            <div className="testi-img">
              <img src="assets/images/testimonial/testi-2.png" alt="" />
            </div>
            <div className="testi-meta">
              <h6>Zoran</h6>
              <p>Bauštela.</p>
              <div className="testi-rating">
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
              </div>
            </div>
          </div>
          <p>
            Gablec je moj svakodnevni izbor koji me ispunjava energijom dovoljno da mogu da radim cjeli dan.
          </p>
        </div>
        <div className="single-testimonial">
          <div className="testi-top">
            <div className="tin-shapes">
              <span className="tsin-1"><img src="assets/images/shapes/33.png" alt="" /></span>
            </div>
            <div className="testi-img">
              <img src="assets/images/testimonial/testi-1.png" alt="" />
            </div>
            <div className="testi-meta">
              <h6>Mirko Mamič</h6>
              <p>CEO Ekoland.</p>
              <div className="testi-rating">
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
              </div>
            </div>
          </div>
          <p>
            Poslovne ručkove i poslovne večere organiziram isključivo uz Gablec.com
          </p>
        </div>
        <div className="single-testimonial">
          <div className="testi-top">
            <div className="tin-shapes">
              <span className="tsin-1"><img src="assets/images/shapes/33.png" alt="" /></span>
            </div>
            <div className="testi-img">
              <img src="assets/images/testimonial/testi-2.png" alt="" />
            </div>
            <div className="testi-meta">
              <h6>Ivan Prenjić</h6>
              <p>CEO Softbit d.o.o.</p>
              <div className="testi-rating">
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
                <span><i className="fas fa-star"></i></span>
              </div>
            </div>
          </div>
          <p>
            Dečke iz fime častim uz Gablec.com. Veliki izbor i visoka kvaliteta su ispunili moje očekivanja.
          </p>
        </div>
      </div>
    </div>
  </section>
  
  
  </>
}
