import React from 'react'

export const Footer = () => {
  return <>
    <footer className="padding-top-40 padding-bottom-40">
      <div className="fo-shapes">
        <span className="fs-1"><img src="assets/images/gallery/26.png" alt="" /></span>
        <span className="fs-2 item-bounce"><img src="assets/images/shapes/25.png" alt="" /></span>
        <span className="fs-3 item-bounce"><img src="assets/images/shapes/26.png" alt="" /></span>
        <span className="fs-4 item-bounce"><img src="assets/images/shapes/27.png" alt="" /></span>
        <span className="fs-5 item-animateTwo"><img src="assets/images/shapes/3.png" alt="" /></span>
        <span className="fs-6"><img src="assets/images/shapes/22.png" alt="" /></span>
        <span className="fs-7"><img src="assets/images/shapes/30.png" alt="" /></span>
      </div>
      <div className="footer-top d-none d-md-block">
        <div className="container">
          <div className="
                row
                align-items-center
                justify-content-between
                padding-bottom-25
              ">
            <div className="col-lg-3 col-md-3">
              <div className="f-logo">
                <a href="index.html">
                  <img src="assets/images/logo/logo.png" alt="" /></a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="f-title">
                <h4>Gladni ste! Naručite<span> frišku hranu.</span></h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-3">
              <a href="shopping-cart.html" className="btn">naručite</a>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div className="footer-bottom padding-top-22 padding-bottom-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
              <div className="widget">
                <h6>adresa</h6>
                <p>Selska cesta 22,  Zagreb, HR 10100 Hrvatska</p>
                <a href="#" className="f-link">pogledaj mapu</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
              <div className="widget">
                <h6>rezervacije</h6>
                <p>Po potrebi catering. Nazovite s povjerenjem</p>
                <a href="#" className="f-link">nazovite</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
              <div className="widget">
                <h6>radno vrjeme</h6>
                <p>
                  <span>ponedeljak-petak: 8 - 16</span> <br /><span>subotom: 9 - 17</span>
                </p>
  
                <a href="#" className="f-link">nazovite</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 margin-bottom-20">
              <div className="widget">
                <h6>novosti na Vaš email</h6>
                <div className="newsletter d-flex">
                  <form action="#">
                    <input type="email" placeholder="enter your email" />
                    <button type="submit">
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </form>
                </div>
                <a href="#" className="f-link">zapišite se</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area text-center">
        <p>Copyright © 2023 <a href="index.html">Gablec.com</a></p>
      </div>
    </footer>
  
    <button className="scrollup"><i className="fas fa-angle-up"></i></button>  </>
}

